import React from 'react'
import Typography from '@mui/material/Typography'
import Layout from '../components/layout'
import ContentContainer from '../components/content-container'

const SobreNosotrosPage = () => (
  <>
    <Layout title="Sobre nosotros">
      <ContentContainer>
        <br></br>
        <Typography variant="h3" gutterBottom>
          Movigy Somos un equipo de inmigrantes apoyados por una red de
          profesionales expertos en temas migratorios. Hemos apoyado a miles de
          familias a mejorar su calidad de vida emigrando a los Estados Unidos
          de forma sencilla y efectiva.
        </Typography>
      </ContentContainer>
    </Layout>
  </>
)
export default SobreNosotrosPage
